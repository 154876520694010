.size {
  font-size: 80px;
  font-weight: bold;
}

.sizePar {
  font-size: 21px;
}

.input-size {
  width: 355px;
  float: left;
}

.button-size {
  width: 95px;
  float: none;
  height: 33px;
}

.padding {
  padding-top: 11px;
}

.video-image-size {
  width: 250px;
  height: auto;
}

.center {
  text-align: center;
  padding-top: 25px;
}

.button-rule {
  font-style: normal;
  font-weight: bold;
  vertical-align: middle;
}

.button-widht {
  width: 116px;
}

.allontana {
  margin-bottom: 30px;
  margin-top: 50px;
  margin-right: 15px;
}

.separa {
  margin-left: 10px;
  margin-right: 10px;
}

.bordered {
  border: 1px solid black;
}

.carousel-inner img {
  margin: 0 auto;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid orange;
  margin: 1em 0;
  padding: 0;
}

.progress {
  border-radius: 0px;
  height: 10px;
}

.progress-bar-warning {
  background: #ffffff;
}

.video-image-size-onWatch {
  width: 150px;
  height: auto;
}

#showcase {
  margin: 0 20px;
  text-align: center;
}
.noUi-horizontal {
  height: 24px !important;
}

.noUi-background {
  background-color: #5b44ed;
}
.noUi-handle {
  height: 32px !important;
  width: 10px !important;
  right: 0 !important;
}

.noUi-handle:before,
.noUi-handle:after {
  display: none !important;
}

.noUi-draggable {
  background: linear-gradient(135deg, #4481ed, #584efd) !important;
}

.noUi-connects {
  background: linear-gradient(
    135deg,
    rgba(68, 129, 237, 0.1),
    rgba(88, 78, 253, 0.1)
  ) !important;
}

#value-span,
#value-input {
  width: 50%;
  float: left;
  display: block;
  text-align: center;
  margin-top: 20px;
}

.video-trim-btn {
  width: 4em;
  padding: 0.3em 1em;
  border-radius: 0.4em;
  font-size: 1.1rem;
  color: #fff;
  background: linear-gradient(135deg, #4481ed, #584efd);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
}
