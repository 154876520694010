.strikethrough-selection {
  text-decoration: line-through;
  color: #aaa;
  position: relative;
  cursor: pointer;
}

.highlighted-selection {
  background-color: purple;
  color: white;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
}

.transcript {
  position: relative;
  font-size: 17px;
  line-height: 1.6;
  cursor: text;
}

.selected-info {
  margin: 10px 20px;
  font-size: 16px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}

.media-icon {
  margin-right: 5px;
}

.wordSpan {
  margin-left: 5px;
}

.selection-menu {
  font-family: "Roboto";
  font-size: 17px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.selection-menu-item {
  margin-right: 10px;
  padding: 4px;
}

.selection-menu-item:hover {
  background-color: #eee;
  border-radius: 3px;
}

.highlight-icon {
  margin-right: 5px;
}
