.search-box {
  display: flex;
  width: 95%;
  margin: 20 auto;
  justify-content: space-between;
}

.search-input {
  flex: 5;
}

.search-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}
