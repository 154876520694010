.html {
  font-size: 62.5%;
  background-color: #fff;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.logo {
  height: 3.2rem;
}

.main-container-box {
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.header-navbar1 {
  background: #fff;
  position: fixed;
  padding: 1rem;
  padding-left: 2rem;
  height: 5rem;
  width: 100%;
  z-index: 999;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.logo img {
  max-width: 100%;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.menu li {
  margin-right: 2.5rem;
}

.menu li:last-child {
  margin-right: 0;
}

.menu li a {
  color: #333;
  text-decoration: none;
}

.cta-btn {
  font-weight: bold;
  text-align: center;
  max-width: 9em;
  margin: 0 3em 0 3em;
  padding: 0.6em 1.4em;
  color: #fff;
  border-radius: 30px;
  background: linear-gradient(135deg, #4481ed, #584efd);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
}

.btn-nav {
  float: right;
  margin-right: 20px;
}

.cta-btn:hover,
.cta-btn:focus {
  opacity: 0.9;
}

.headline-section {
  padding-top: 6rem;
}

.main-headline {
  font-size: 3.9rem;
  font-weight: 800;
  margin-top: 3rem;
  line-height: 5rem;
  color: #212529;
  font-family: "Open Sans";
}

.main-headline-v4 {
  font-size: 3.3rem;
  font-weight: 900;
  margin-top: 3rem;
  line-height: 1.2;
  color: #212529;
  font-family: "Helvetica Neue";
}

.sub-headline {
  font-size: 1.3rem;
  color: #000;
  font-weight: 400;
  font-family: "Helvetica Neue";
}

.sub-headline-v4 {
  font-size: 1.3rem;
  color: #5d5d62;
  font-weight: 400;
  font-family: "Helvetica Neue";
}

.btn {
  text-transform: unset !important;
}

.d-btn-cta {
  font-size: 1.1rem;
  font-weight: 400;
  border-radius: 0.5rem;
  padding: 0.7rem;
  padding-inline: 1rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #584efd;
}

.d-btn-cta:hover {
  background-color: #2c16bf;
}

.demo-section {
  width: 100%;
}

.demo-container {
  position: relative;
  max-width: 70rem;
  height: 34rem;
  margin: auto;
  border-radius: 1rem;
  cursor: pointer;
  background-image: "";
}

.demo-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 9rem;
  width: 9rem;
  background-color: rgba(91, 68, 237, 0.9);
  border-radius: 4.5rem;
}

.demo-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6rem;
  color: #eee;
  cursor: pointer;
  z-index: 9;
}

/* .demo-play-icon:hover {
    transform: scale(1.3);
  } */

.demo-placeholder-img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  opacity: 0.9;
  border: 1px solid #eee;
}

.examples-section {
  width: 100%;
  padding-top: 5rem;
  margin-top: 5rem;
}

.examples-section-heading {
  max-width: 60%;
  margin: auto;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 700;
  line-height: 3rem;
  color: #212529;
  font-family: "Helvetica";
}

.sm-video-container {
  max-width: 80%;
  margin: auto;
  height: 24em;
  margin-top: 4.3em;
}

.feature-section {
  background-color: #f3f7fa;
  width: 100%;
  padding-top: 5rem;
  margin-top: 5rem;
}

.section-heading {
  max-width: 60%;
  margin: auto;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;
  line-height: 3rem;
  color: #212529;
  font-family: "Helvetica";
}

.section-sub-heading {
  max-width: 60%;
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  text-align: center;
  color: #212529;
  font-family: "Helvetica";
}

.feature-container {
  width: 90%;
  margin: auto;
  margin-top: 5rem;
  padding-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
}

.feature-item {
  display: flex;
  height: 13rem;
  width: 25rem;
  border: 1px solid #eee;
  border-radius: 0.8rem;
  margin: 0.6rem;
  background-color: #fff;
}

.ft-icon {
  font-size: 30px;
}

.feature-icon {
  padding-top: 1rem;
  padding-inline: 1rem;
  color: #4482ed;
  font-size: 2rem;
  justify-content: center;
  width: 12%;
  height: 100%;
}

.feature-content {
  padding-left: 1rem;
  padding-right: 0.8rem;
  width: 88%;
  height: 100%;
}

.feature-heading {
  width: 100%;
  padding: 0.5rem;
  text-align: left;
  color: #212529;
  font-weight: 600;
  font-size: 1.3rem;
  margin-top: 0.5rem;
  font-family: sans-serif;
}

.feature-details {
  padding-inline: 0.5rem;
  color: #64748b;
  text-align: left;
  font-size: 1.1rem;
  width: 100%;
}

.product-info-section {
  background-color: #fff;
  width: 100%;
  padding-inline: 4rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  padding-bottom: 3rem;
}

.product-info-feature-container {
  padding: 2rem;
  padding-left: 4rem;
}

.product-info-container {
  padding: 2rem;
}

.product-info {
  padding-top: 4rem;
}

.product-info-headline {
  width: 100%;
  text-align: left;
  padding: 1rem;
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 600;
  color: #212529;
  font-family: sans-serif;
}

.product-info-detail {
  padding-left: 1rem;
  font-size: 1.2rem;
  color: #5d5d62;
}

.product-info-table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.product-info-list-items {
  font-size: 1.1rem;
  border-collapse: separate;
  border-spacing: 0 1em;
}

.list-item-num {
  font-size: 1.5rem;
  text-align: center;
  width: 2.4rem;
  height: 2.4rem;
  background-color: #4482ed;
  color: #ffffff;
  border-radius: 1.2rem;
  margin-right: 0.8rem;
}

.product-info-demo {
  max-width: 35rem;
  border: 1px solid rgb(129, 126, 126);
  border-radius: 1rem;
  padding: 1.5rem;
  background-color: rgba(91, 68, 237, 0.1);
}

.product-info-demo-img {
  height: 30rem;
}

.corousal-section {
  background-color: #f3f7fa;
  width: 100%;
  padding-top: 5rem;
  margin-top: 5rem;
}

.corousal-section-heading {
  max-width: 60%;
  margin: auto;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;
  line-height: 3rem;
  color: #212529;
  font-family: "Helvetica";
}

.corousal-section-subheading {
  max-width: 60%;
  margin: auto;
  font-size: 1.6rem;
  font-style: italic;
  text-align: center;
  line-height: 3rem;
  color: #64748b;
  font-family: "Open Sans";
}

.corousal-section-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 5rem;
  padding-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
}

.sample-container {
  width: 15rem;
}

.anim-demo-container {
  border: 1px solid #ddd;
  background-color: #eeecfd;
  border-radius: 5;
  padding: 1.2em;
}

.final-cta-section {
  background-color: #f3f7fa;
  width: 100%;
  padding-top: 5rem;
  margin-top: 5rem;
  padding-bottom: 5rem;
}

.final-section-cta {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}

.max-w-full {
  max-width: 100%;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.max-h-full {
  max-height: 100%;
}

.h-full {
  height: 100%;
}
.aspect-video {
  aspect-ratio: 16/9;

  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      float: left;
      padding-top: 56.25%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

.dg-content {
  background-color: #ffffff;
}

/* .aspect-video {
    width: 100%;
    padding-top: calc(9 / 16 * 100%);
  } */

@media (max-width: 998px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 50%;
  }

  .btn-nav {
    margin-right: 0px;
    margin-left: 0px;
  }

  .product-info-feature-container {
    width: 100%;
  }

  .product-info-demo-wrapper {
    max-width: 45rem;
    margin: auto;
  }

  .product-info-container {
    margin: auto;
  }

  .product-info {
    background-color: #f8f8f8;
    margin-bottom: 3rem;
    border-radius: 1rem;
  }

  .product-info-section {
  }

  .feature-container {
    width: 60%;
  }

  .feature-item {
    display: inline-block;
    width: 42%;
    height: 22em;
    border-radius: 0.8rem;
    margin: 0.8rem;
    background-color: #fff;
  }

  .feature-icon {
    width: 100%;
    height: 25%;
    margin: auto;
    padding-top: 1rem;
    padding-inline: 1rem;
    color: #4482ed;
    font-size: 2.5rem;
    align-items: center;
    justify-content: center;
  }

  .feature-heading {
    font-size: 1.5rem;
  }

  .feature-content {
    width: 100%;
    height: 75%;
    padding-left: 1rem;
    padding-right: 0.8rem;
    font-size: 1.5rem;
  }

  .sm-video-container {
    max-width: 90%;
    margin: auto;
    height: 35em;
    margin-top: 4.3em;
  }
}

/* @media (max-width: 440px) {
    .feature-item {
      display: inline-block;
      width: 21rem;
      height: 24em;
      border-radius: 0.8rem;
      margin: 0.8rem;
      background-color: #fff;
    }
  
    .feature-icon {
      width: 30%;
      height: 20%;
      margin: auto;
      padding-top: 1rem;
      padding-inline: 1rem;
      color: #4482ed;
      font-size: 2rem;
      justify-content: center;
    }
  
    .feature-content {
      width: 100%;
      height: 80%;
      padding-left: 1rem;
      padding-right: 0.8rem;
    }
  
    .sm-video-container {
      width: 80%;
      margin: auto;
      height: 18em;
      margin-top: 4.3em;
    }
  
    .product-info-demo-wrapper {
      max-width: 35rem;
      margin: auto;
    }
  } */

/* body {
    -webkit-font-smoothing: antialiased;
    background-color: rgba(0, 0, 0, 0.8);
  margin:0;
  } */

section {
  color: #7a90ff;
  padding: 2em 0;
  min-height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  z-index: 10;
}

.pricing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 auto;
}

.pricing-item {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  align-items: stretch;
  text-align: center;
  -webkit-flex: 0 1 400px;
  flex: 0 1 400px;
}

.pricing-action {
  color: inherit;
  border: none;
  background: none;
  cursor: pointer;
}

.pricing-action:focus {
  outline: none;
}

.pricing-feature-list {
  text-align: left;
}

.pricing-palden .pricing-item {
  font-family: "Open Sans", sans-serif;
  cursor: default;
  color: #84697c;
  background: #fff;
  border-radius: 20px 20px 10px 10px;
  margin: 1em;
}

@media screen and (min-width: 66.25em) {
  .pricing-palden .pricing-item {
    margin: 1em -0.5em;
  }
  .pricing {
    width: 100%;
  }

  .pricing-palden .pricing__item--featured {
    margin: 0;
    z-index: 10;
    box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
  }
}

.pricing-palden .pricing-deco {
  border-radius: 10px 10px 0 0;
  background: "#fff";
  padding: 3em 0 3em;
  position: relative;
}

.pricing-palden .pricing-deco-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
}

.pricing-palden .pricing-title {
  font-size: 0.75em;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #fff;
}

.pricing-palden .deco-layer {
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.pricing-palden .pricing-item:hover .deco-layer--1 {
  -webkit-transform: translate3d(15px, 0, 0);
  transform: translate3d(15px, 0, 0);
}

.pricing-palden .pricing-item:hover .deco-layer--2 {
  -webkit-transform: translate3d(-15px, 0, 0);
  transform: translate3d(-15px, 0, 0);
}

.pricing-palden .icon {
  font-size: 2.5em;
}

.pricing-palden .pricing-price {
  font-size: 4em;
  font-weight: bold;
  padding: 0;
  color: #fff;
  margin: 0 0 0.25em 0;
  line-height: 0.75;
}

.pricing-palden .pricing-currency {
  font-size: 0.15em;
  vertical-align: top;
}

.pricing-palden .pricing-period {
  font-size: 0.15em;
  padding: 0 0 0 0.5em;
  font-style: italic;
}

.pricing-palden .pricing__sentence {
  font-weight: bold;
  margin: 0 0 1em 0;
  padding: 0 0 0.5em;
}

.pricing-palden .pricing-feature-list {
  margin: 0;
  padding: 1em 3em 1.5em;
  list-style: none;
  text-align: left;
}

.pricing-palden .pricing-feature {
  padding: 0.5em 0;
}

.feature-check-icon {
  margin-right: 1em;
  font-size: 1em;
}

.pricing-palden .pricing-action {
  font-weight: bold;
  margin: 0 3em 0 3em;
  padding: 1em 2em;
  color: #fff;
  border-radius: 30px;
  background: linear-gradient(135deg, #a93bfe, #584efd);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.pricing-palden .pricing-action:hover,
.pricing-palden .pricing-action:focus {
  opacity: 0.9;
}

.pricing-palden .pricing-item--featured .pricing-deco {
  padding: 5em 0 8.885em 0;
}

@import url(//fonts.googleapis.com/css?family=Lato:300:400);

.header {
  position: relative;
  text-align: center;
  color: white;
}

.inner-header {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 150vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 250px;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

.product-feature-navigator-menu {
  padding: 30px;
  font-family: "Open Sans";
}

.product-feature-list-item {
  width: 200px;
  padding: 5px;
  color: #000;
  cursor: pointer;
}

.product-feature-list-item:hover {
  color: #584efd;
  text-decoration: underline;
}

.product-feature-list-item-selected {
  width: 200px;
  padding: 5px;
  text-decoration: underline;
  color: #584efd;
  cursor: pointer;
  border-radius: 5px;
}

.product-feature-demo-container {
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.product-feature-demo-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.product-feature-section {
  margin-top: 20px;
  padding-top: 20px;
  margin-bottom: 8em;
}

.product-feature-demo {
  width: 90%;
  margin: auto;
  border-radius: 10px;
  margin-top: 20px;
  aspect-ratio: 16/9;
}
