.form-row {
  display: flex;
  padding: 0.3rem;
  align-items: center;
  width: 95%;
  margin: auto;
  margin-top: 1rem;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  flex-wrap: wrap;
  background-color: #fff;
}

.label {
  flex: 1;
}

.edit-box {
  display: flex;
  flex: 2;
  justify-content: end;
  align-items: center;
}

.options-container {
  margin-top: 0.6rem;
  display: flex;
  flex: 1 0 100%;
  justify-content: end;
}

.options-form-row {
  display: flex;
  padding: 0.3rem;
  align-items: center;
  width: 17.2rem;
  margin: auto;
  margin-top: 1rem;
}

.options-label {
  flex: 1;
}

.options-edit-box {
  display: flex;
  flex: 2;
  justify-content: end;
}

.lettercase-input-container {
  display: flex;
}

.lettercase-input-item1 {
  border: 1px solid #eee;
  padding: 0.4rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
}

.lettercase-input-item2 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 0.4rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  cursor: pointer;
}

.lettercase-input-item3 {
  border: 1px solid #eee;
  padding: 0.4rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
}

.lettercase-input-item-active {
  background-color: rgba(74, 144, 226, 1);
  color: #fff;
}

.text-align-input-container {
  display: flex;
}

.text-align-item1 {
  border: 1px solid #eee;
  padding: 0.3rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
}

.text-align-item2 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 0.3rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  cursor: pointer;
}

.text-align-item3 {
  border: 1px solid #eee;
  padding: 0.3rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
}

.text-align-item-active {
  background-color: rgba(74, 144, 226, 1);
  color: #fff;
}

/* Slider base styles */
.labeled-slider {
  width: 16.5rem;
}

.labeled-slider-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labeled-slider-label {
  font-weight: 500; /* font-medium */
}

.labeled-slider-value {
  font-size: 0.775rem; /* text-sm */
  color: rgb(75, 85, 99); /* text-gray-600 */
}

.labeled-slider-input {
  width: 100%;
  height: 0.5rem; /* h-2 */
  background-color: rgb(229, 231, 235); /* bg-gray-200 */
  border-radius: 0.5rem; /* rounded-lg */
  appearance: none;
  cursor: pointer;
}

/* Optional: Custom slider thumb styling */
.labeled-slider-input::-webkit-slider-thumb {
  appearance: none;
  width: 1rem;
  height: 1rem;
  background: #4a90e2;
  border-radius: 50%;
  cursor: pointer;
}

.toggle-switch {
  width: 2.7rem;
  height: 1.4rem;
  margin: 0.25rem;
  border-radius: 9999px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-switch--disabled {
  background-color: #9ca3af; /* Gray */
}

.toggle-switch--enabled {
  background-color: #4481ed; /* Pink */
}

.toggle-switch__circle {
  position: absolute;
  top: 0.2rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s ease;
}

.toggle-switch__circle--disabled {
  transform: translateX(0.25rem);
}

.toggle-switch__circle--enabled {
  transform: translateX(1.55rem);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.number-input-box {
  border: 1px solid #eee;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0.4rem;
}

.number-input-box:focus {
  outline: none; /* Removes the default outline */
}

.select-box {
  display: flex;
  width: 11rem;
  height: 2.2rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem;
  border: 1px solid #eee;
  border-radius: 5px;
  cursor: pointer;
}

.chevron-container {
  display: flex;
  height: 2.125rem;
  border: 1px solid #eee;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
}

.chevron-button {
  flex: 1 1 0%;
  font-size: 1rem;
  cursor: pointer;
}

.chevron-button:hover {
  background-color: rgba(74, 144, 226, 1);
}
