/* body {
  background-color: #ebecf0;
} */

.text-container {
  width: 100%;
  min-height: 90%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #eee;
  overflow-y: auto;
  word-wrap: break-word;
  font-size: 1.1rem;
}

.create-btn {
  float: right;
  margin: 0 3em 0 3em;
  padding: 0.6em 1.4em;
  border-radius: 1em;
  font-size: 1rem;
  color: #fff;
  background: linear-gradient(135deg, #4481ed, #584efd);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
}

.cta-btn:focus,
.create-btn:hover {
  opacity: 0.9;
}

.dropdown-menu-item {
  width: 95%;
  margin: 0 auto;
  padding: 20px 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-selected-item {
  width: 95%;
  margin: 0 auto;
  padding: 20px 10px;
  margin-bottom: 10px;
  background-color: #ebedff;
  border: 1px solid #eee;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-menu-item:hover {
  background-color: #eee;
}

.main-container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 82%;
  height: 100%;
  overflow: scroll;
}

.menubar-profile-logo {
  padding-left: 20px;
}

.menubar-profile-details {
  align-items: left;
  padding-left: 20px;
  overflow-x: clip;
}

.menubar-profile-email {
  font-size: 13px;
}

.menubar-item {
  cursor: pointer;
  font-weight: 500;
  height: 40px;
  padding: 5px;
  padding-left: 20px;
  width: 100%;
}

.menubar-item:hover {
  background-color: #eee;
}

.sidebar-menu {
  min-height: 100%;
  padding-top: 1.5rem;
  border-right: 1px solid #eee;
  width: 18%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  float: left;
  transition: 0.3s width;
}

.box-item-container {
  width: 90%;
  font-size: 1rem;
  padding: 10px;
  padding-inline: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.box-item-active {
  width: 90%;
  font-size: 1rem;
  padding: 10px;
  padding-inline: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  margin-top: 0.5rem;
  background-color: #edf1f8;
}

.box-item-container:hover {
  background-color: #f6f7f8;
}

.menu-description {
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3rem;
  z-index: 10;
  font-weight: 700;
  margin: 0;
  color: black;
  transition: 0.2s opacity;
}

.st-aspect-ratio-option-container {
  width: 100px;
  float: "left";
  margin-bottom: 30px;
}

.st-aspect-ratio-option-container-last {
  width: 100px;
  float: "left";
}

.upload-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 18rem;
  cursor: pointer;
  margin-top: 10px;
  border-color: #d6e4ff;
  border-style: dashed;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
}

.loading-bar {
  font-family: "Open Sans", sans-serif;
  width: 40%;
  height: 0.5rem;
  display: block;
  position: relative;
  color: #212121;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem auto;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ddd;
  overflow: hidden;
  z-index: 1;
}

.loading-bar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;
  z-index: 0;
  border-radius: 10px;
  background-color: #584efd;
  -webkit-animation: loadBar 2s linear infinite;
  animation: loadBar 2s linear infinite;
}

.input-menu {
  display: flex;
  width: 15.75rem;
  height: 6.25rem;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 20px;
  font-weight: 500;
  border: 1px dashed #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.input-menu:hover {
  color: #4482ed;
  border: 1px dashed #4482ed;
}

@-webkit-keyframes loadBar {
  0% {
    left: -110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes loadBar {
  0% {
    left: -110%;
  }

  100% {
    left: 110%;
  }
}
