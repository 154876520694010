.body {
  background-color: #eee;
}

.sidebar {
  height: 100%;
  width: 100%;
  padding-top: 10px;
  padding-inline: 5px;
  float: left;
  margin: auto;
}

.menu-item {
  position: relative;
  min-height: 40px;
  width: 40px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  color: #343a40;
}

.menu-item:hover {
  color: #584efd;
}

.menu-item-selected {
  position: relative;
  min-height: 40px;
  width: 40px;
  color: #584efd;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #584efd;
  background-color: #fff;
  margin-top: 10px;
}

.menu-icon {
  position: absolute;
  top: 5px;
  font-size: 18px;
  width: 100%;
  height: 30px;
  text-align: center;
}

.menu-item-text {
  position: absolute;
  top: 29px;
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.upload-block {
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
  border-color: #d6e4ff;
  border-style: dashed;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
}

.media-menu-item {
  display: inline-block;
  background: #eee;
  border-radius: 10px;
  text-align: center;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.selected-media-menu-item {
  display: inline-block;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.toolbox-section-header {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.toolbox-items-container {
  display: block;
  margin-top: 20px;
  overflow: scroll;
  height: 65%;
}

.media-item {
  height: 90px;
  position: relative;
  object-fit: cover;
  object-position: top left;
  cursor: pointer;
  margin-left: 10px;
  overflow: hidden;
  border-radius: 3px;
  margin: 10px;
  border: 1px solid #ddd;
}

.script-container {
  width: 100%;
  min-height: 400px;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #eee;
  overflow-y: auto;
  word-wrap: break-word;
  font-size: 1.1rem;
}

.text-content {
  border: none;
  resize: none;
}

.text-content:focus {
  outline: none; /* Removes the default outline */
  border: none; /* Removes the default border */
  cursor: text;
}

.create-btn {
  float: right;
  margin: 0 3em 0 3em;
  padding: 0.6em 1.4em;
  border-radius: 1em;
  font-size: 1rem;
  color: #fff;
  background: linear-gradient(135deg, #4481ed, #584efd);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
}

.cta-btn:focus,
.create-btn:hover {
  opacity: 0.9;
}

.action-btn-primary {
  float: right;
  padding: 0.5em 1.2em;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(135deg, #4481ed, #584efd);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
}

.action-btn-disabled {
  float: right;
  padding: 0.5em 1.2em;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(135deg, #4481ed, #584efd);
  opacity: 0.8;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
}

.action-btn-primary:focus,
.action-btn-primary:hover {
  opacity: 0.9;
}

.action-btn-secondary {
  float: right;
  padding: 0.5em 1.2em;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #584efd;
  border: 1px solid #584efd;
  /* background: linear-gradient(
    135deg,
    rgba(68, 129, 237, 0.5),
    rgba(88, 78, 253, 0.5)
  ); */
  background: linear-gradient(
    135deg,
    rgba(237, 237, 237, 0.5),
    rgba(255, 255, 255, 0.5)
  );
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
  opacity: 0.8;
}

.action-btn-secondary:focus,
.action-btn-secondary:hover {
  opacity: 0.7;
}

.aspect-ratio-option-container {
  width: 70%;
  margin: auto;
  margin-bottom: 30px;
}

.aspect-ratio-option-container-last {
  width: 70%;
  margin: auto;
}

.aspect-ratio-option {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}

.aspect-ratio-option:hover {
  border: 1px solid #584efd;
}

.aspect-ratio-option-selected {
  position: relative;
  border: 1px solid #584efd;
  background-color: rgba(88, 78, 253, 0.1);
  border-radius: 5px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}

.caption-option {
  display: inline-block;
  width: 155px;
  height: 91px;
  margin: 5px;
  border-radius: 15px;
  cursor: pointer;
}

.caption-option-selected {
  display: inline-block;
  width: 155px;
  height: 91px;
  margin: 5px;
  border-radius: 15px;
  border: 3px solid rgba(91, 68, 237, 0.8);
  cursor: pointer;
}

.pos-item-block {
  display: inline-block;
  margin-left: 10px;
  height: 48px;
  width: 39px;
  padding: 2px;
  border: 1px solid #f5f5f5;
  cursor: pointer;
}

.pos-item-block-selected {
  display: inline-block;
  margin-left: 10px;
  height: 48px;
  width: 39px;
  padding: 2px;
  border: 2px solid rgba(91, 68, 237, 0.8);
  cursor: pointer;
}

.pos-sub-item {
  width: 100%;
  height: 10px;
  margin-bottom: 2px;
  border: 1px solid #ddd;
}

.pos-sub-item-main {
  width: 100%;
  height: 16px;
  margin-bottom: 2px;
  border: 1px solid #aaa;
  background-color: rgba(0, 0, 0, 0.5);
}

.pos-sub-item-last {
  margin-bottom: 0px;
}

.loader-container {
  height: 100vh;
  width: 100%;
  background: linear-gradient(145deg, #fff 0%, #ebecf0 30%, #fff 80%);
}

.loading-bar {
  font-family: "Open Sans", sans-serif;
  width: 40%;
  height: 1.5rem;
  display: block;
  position: relative;
  color: #212121;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem auto;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ddd;
  overflow: hidden;
  z-index: 1;
}

.loading-bar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 0;
  border-radius: 10px;
  background-color: #584efd;
  -webkit-animation: loadBar 2s linear infinite;
  animation: loadBar 2s linear infinite;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.modal-close {
  position: absolute;
  font-family: "Arial";
  top: 5px;
  right: 15px;
  color: #aaa;
  font-size: 1.75rem;
  cursor: pointer;
}

.modal-close:hover {
  color: #212529;
}

.video-slider-container {
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
  flex-direction: column;
  width: 100%;
}
.slider {
  position: relative;
  width: 100%;
  height: 6px;
  background-color: #ddd;
  cursor: pointer;
  margin-bottom: 10px;
}
.progress-bar {
  position: absolute;
  height: 100%;
  background-color: #007bff;
  transition: width 0.1s linear;
}
.timer {
  font-family: monospace;
  margin-top: 5px;
}

@-webkit-keyframes loadBar {
  0% {
    left: -110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes loadBar {
  0% {
    left: -110%;
  }

  100% {
    left: 110%;
  }
}
