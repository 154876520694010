.input-box {
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  padding: 0.4rem;
  font-size: 0.8rem;
}

.input-box-hashtag {
  display: inline-flex;
  padding-left: 0.5rem;
  align-items: center;
  color: #7f7f7f;
}

.input-value-box {
  display: flex;
  flex: 1 1 0%;
  outline: none;
  padding-left: 0.125rem;
  padding-right: 0.125rem;
  height: 100%;
  align-items: center;
  min-width: 80px;
}

.disable-bx-square {
  display: flex;
  width: 2.5rem;
  border-right: 1px solid #eee;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 10px;
  position: relative;
  cursor: pointer;
}

.disable-bx-diagonal-line:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    to top right,
    transparent calc(50% - 1px),
    #ddd,
    transparent calc(50% + 1px)
  );
}
